<template>
  <div>
    <div class="detail">
      <div class="detail-para">
        PilotGo是麒麟软件在openEuler社区孵化的插件式运维管理平台。本次PilotGo针对NestOS平台特性及最佳应用实践，为NestOS平台带来了定制化的运维管理功能及全新的架构感知插件特性。
      </div>

      <div class="detail-para">
        <p class="detail-para-title">● 功能概览：</p>
        <div class="detail-para-one">
          <p>1、NestOS平台双模式纳管及日常运维处理；</p>
          <p>2、单机资源及进程结构可视化；</p>
          <p>3、多机应用集群拓扑图展示。</p>
        </div>
      </div>

      <div class="detail-para">
        <p class="detail-para-title">● 效果展示：</p>
        <div class="detail-para-one">
          <p>
            <span>1、PilotGo 纳管 NestOS 系统；</span>
            <img src="~@/assets/arch/two_1.png"/>
          </p>
          <p>
            <span>2、集群拓扑展示；</span>
            <img src="~@/assets/arch/two_2.png"/>
          </p>
          <p>
            <span>3、集群拓扑节点信息展示。</span>
            <img src="~@/assets/arch/two_3.png"/>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style lang="scss" scoped>
@import "style";
</style>